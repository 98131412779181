<style global lang="postcss">
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

  @tailwind base;

  @layer base {
    img,
    audio,
    video,
    svg {
      @apply uc-inline;
    }
  }
  @tailwind components;
  @tailwind utilities;

  input[type="date"]::-webkit-calendar-picker-indicator {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    position: absolute;
    width: auto;
  }

  @supports (-webkit-touch-callout: none) {
    input[type="date"]:before {
      content: attr(placeholder) !important;
    }
    input[type="date"]:focus:before {
      content: "" !important;
    }
  }
</style>

<script lang="ts">
  import {ENV} from "./utils/constants";
  import Routes from "./routes.svelte";
  import Modal from "./components/Modal.svelte";
  import {showModal, partnerId} from "./stores/stores";

  export let partner: string;
  export let isWebView: boolean;
  export let boxShadow: boolean;

  partnerId.set(partner);

  let showModalValue: boolean;

  showModal.subscribe(value => (showModalValue = value));
</script>

<svelte:head>
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, maximum-scale=1"
  />
  <meta
    http-equiv="Content-Security-Policy"
    content="upgrade-insecure-requests"
  />
  {#if ENV === "Production"}
    <script>
      console.log("prod");
      !(function () {
        var analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error("Segment snippet included twice.");
          else {
            analytics.invoked = !0;
            analytics.methods = [
              "trackSubmit",
              "trackClick",
              "trackLink",
              "trackForm",
              "pageview",
              "identify",
              "reset",
              "group",
              "track",
              "ready",
              "alias",
              "debug",
              "page",
              "once",
              "off",
              "on",
              "addSourceMiddleware",
              "addIntegrationMiddleware",
              "setAnonymousId",
              "addDestinationMiddleware",
            ];
            analytics.factory = function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                t.unshift(e);
                analytics.push(t);
                return analytics;
              };
            };
            for (var e = 0; e < analytics.methods.length; e++) {
              var key = analytics.methods[e];
              analytics[key] = analytics.factory(key);
            }
            analytics.load = function (key, e) {
              var t = document.createElement("script");
              t.type = "text/javascript";
              t.async = !0;
              t.src =
                "https://cdn.segment.com/analytics.js/v1/" +
                key +
                "/analytics.min.js";
              var n = document.getElementsByTagName("script")[0];
              n.parentNode.insertBefore(t, n);
              analytics._loadOptions = e;
            };
            analytics._writeKey = "NAHSKF5Rz7xrhjG89lERoNp8CWzmQDNU";
            analytics.SNIPPET_VERSION = "4.15.3";
            analytics.load("NAHSKF5Rz7xrhjG89lERoNp8CWzmQDNU");
            analytics.page();
          }
      })();
    </script>
  {:else}
    <script>
      console.log("dev");
      !(function () {
        var analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error("Segment snippet included twice.");
          else {
            analytics.invoked = !0;
            analytics.methods = [
              "trackSubmit",
              "trackClick",
              "trackLink",
              "trackForm",
              "pageview",
              "identify",
              "reset",
              "group",
              "track",
              "ready",
              "alias",
              "debug",
              "page",
              "once",
              "off",
              "on",
              "addSourceMiddleware",
              "addIntegrationMiddleware",
              "setAnonymousId",
              "addDestinationMiddleware",
            ];
            analytics.factory = function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                t.unshift(e);
                analytics.push(t);
                return analytics;
              };
            };
            for (var e = 0; e < analytics.methods.length; e++) {
              var key = analytics.methods[e];
              analytics[key] = analytics.factory(key);
            }
            analytics.load = function (key, e) {
              var t = document.createElement("script");
              t.type = "text/javascript";
              t.async = !0;
              t.src =
                "https://cdn.segment.com/analytics.js/v1/" +
                key +
                "/analytics.min.js";
              var n = document.getElementsByTagName("script")[0];
              n.parentNode.insertBefore(t, n);
              analytics._loadOptions = e;
            };
            analytics._writeKey = "oRuDBMpEUiRIpCAxFjUzC7gMTijoEwLT";
            analytics.SNIPPET_VERSION = "4.15.3";
            analytics.load("oRuDBMpEUiRIpCAxFjUzC7gMTijoEwLT");
            analytics.page();
          }
      })();
    </script>
  {/if}
</svelte:head>

<div
  class="uc-font-Montserrat uc-flex uc-items-end mobile:uc-items-center uc-bg-color-antiflash-white/10"
>
  <div
    class={`uc-relative ${
      isWebView
        ? "uc-h-[90vh] uc-w-full mobile:uc-h-size-widget-height mobile:uc-w-size-widget-width mobile:uc-rounded-md uc-rounded-t-large"
        : "uc-h-size-widget-height uc-w-size-widget-width uc-rounded-md"
    } uc-bg-white ${boxShadow ? "uc-shadow-primary" : ""} `}
  >
    <Routes />
    {#if showModalValue}
      <Modal />
    {/if}
  </div>
</div>
